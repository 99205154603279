<template>
	<div>
		<layout footer="标准来源：《康一生健康管理系统》、《康一生营养运动差值量化管理系统》">
			<daily-standard-cmp :daily-rate="dailyRate.rydpj"></daily-standard-cmp>
			<daily-rate-cmp :fact-arr="factArr1" />
		</layout>
		<layout footer="标准来源：《康一生健康管理系统》、《康一生营养运动差值量化管理系统》">
			<div class="daily-container">
				<daily-rate-cmp :fact-arr="factArr2" />
				
				<div class="daily-rate">
					<div class="daily-rate-text">
						<img src="@/assets/img/manage/info-icon.png" alt="img" class="drt-img">
						<span class="drt-text">日运动评价：</span>
					</div>
					<div class="daily-card">
						<div class="daily-rate-textarea" v-html="replaceBreak(dailyRate.rydpj.pjy)"></div>
						<!-- <div class="daily-score">
							<Input-number :min="1" v-model="score" style="width: 63px;"></Input-number>
							<span>分</span>
						</div> -->
					</div>
				</div>
			</div>
		</layout>
	</div>
</template>

<script>
import Layout from './layout';
import dailyRateCmp from './daily-rate-cmp';
import dailyStandardCmp from './daily-standard-cmp';
import { mapGetters } from 'vuex';
export default {
	data() {
		return {
			score: 0,
		};
	},
	computed: {
		...mapGetters({
			dailyRate: 'yyAllData',
		}),
		factArr1() {
			return this.dailyRate.rydpj.sjydqx.slice(2, 5);
		},
		factArr2() {
			return this.dailyRate.rydpj.sjydqx.slice(5);
		},
	},
	components: {
		Layout,
		dailyStandardCmp,
		dailyRateCmp,
	},
	methods: {
		replaceBreak(val) {
			if (val) return val.replace(/\r/g, '<br>');
			else return '';
		},
	},
};
</script>

<style lang="less" scoped>
	.daily-rate {
		padding-bottom: 20px;

		.daily-rate-text {
			.drt-img {
				width: 40px;
				margin-right: 10px;
			}

			.drt-text {
				font-size: 18px;
				font-weight: 700;
				color: #005ab3;
			}
		}

		.daily-card {
			display: flex;
			margin-top: 8px;

			.daily-rate-textarea {
				display: block;
				border: 1px solid #005ab3;
				padding: 8px;
				flex: 1;
				box-sizing: border-box;
				min-height: 125px;
				font-size: 16px;
				color: #005AB3;
				font-weight: 700;
			}
		}
	}
</style>
