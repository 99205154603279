<template>
	<div class="quantitative-container">
		<navi-title title_ch="量化指标" title_en="QUANTITATIVE INDICATORS">
			<div class="daily-score">
				<Input-number :min="1" v-model="score" style="width: 63px;" @on-change="onChangeScore"></Input-number>
				<span>分</span>
			</div>
		</navi-title>
		<div class="quantitative-content">
			<div class="qt-wrap border">
				<h3 class="title">未来</h3>
				<div class="qt-card">
					<img src="@/assets/img/manage/8-1.png" alt="img" class="qt-img">
					<div class="qt-panel">
						<div class="qt-panel-item">
							<p class="qpi-text">【摄入】</p>
							<img src="@/assets/img/manage/8-3.png" alt="img" class="qpi-img">
							<p class="qpi-kcal">{{ quantitativeData.wlsr }}kcal</p>
						</div>
						<div class="qt-panel-item">
							<p class="qpi-text">【消耗】</p>
							<img src="@/assets/img/manage/8-2.png" alt="img" class="qpi-img">
							<p class="qpi-kcal">{{ quantitativeData.wlxh }}kcal</p>
						</div>
						
						<div class="qt-panel-item" style="padding-left: 15px;padding-top: 86px">
							<p class="qpi-text" style="font-size: 14px">基础代谢率</p>
							<p class="qpi-kcal">{{ userInfo.jcdx }}kcal</p>
						</div>
					</div>
				</div>
			</div>
			<div class="qt-wrap">
				<h3 class="title">调整中</h3>
				<div class="qt-card">
					<img src="@/assets/img/manage/8-4.png" alt="img" class="qt-img">
					<div class="qt-panel">
						<div class="qt-panel-item">
							<p class="qpi-text">【摄入】</p>
							<img src="@/assets/img/manage/8-3.png" alt="" class="qpi-img">
							<p class="qpi-kcal">{{ sjsr + sr }}kcal</p>
						</div>
						<div class="qt-panel-item">
							<p class="qpi-text">【消耗】</p>
							<img src="@/assets/img/manage/8-2.png" alt="" class="qpi-img">
							<p class="qpi-kcal">{{ sjxh + xh }}kcal</p>
						</div>
						<div class="qt-panel-item" style="padding-left: 15px;padding-top: 86px">
							<p class="qpi-text" style="font-size: 14px">基础代谢率</p>
							<p class="qpi-kcal">{{ userInfo.jcdx }}kcal</p>
						</div>
					</div>
				</div>
			</div>

			<div class="qt-footer">
				<span class="qt-footer-text">调整值</span>
				<div class="qt-card">
					<div class="qf-item">
						<span>摄入</span>
						<div style="text-align: center;">
							<p>实际摄入{{sjsr}}kcal</p>
							<Input-number :max="150" :min="-150" v-model="sr" class="qf-kcal" @on-change="changeSr"></Input-number>
						</div>
						<span>kcal</span>
					</div>
					<div class="qf-item">
						<span>消耗</span>
						<div style="text-align: center;">
							<p>实际消耗{{sjxh}}kcal</p>
							<Input-number :max="150" :min="-150" v-model="xh" class="qf-kcal" @on-change="changeXh"></Input-number>
						</div>
						<span>kcal</span>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
import naviTitle from 'components/naviTitle/title';
import { mapGetters, mapMutations } from 'vuex';
export default {
	data() {
		return {
			quantitativeData: [],
			sr: 0,
			xh: 0,
			sjsr: 0, // 实际摄入
			sjxh: 0, // 实际消耗
			score: 0,
		};
	},
	computed: {
		...mapGetters({ data: 'yyAllData', userInfo: 'reportUserData' }),
	},
	components: {
		naviTitle,
	},
	methods: {
		...mapMutations(['setQuantitativeSr', 'setQuantitativeXh', 'setQuantitativeScore']),
		changeSr() {
			this.setQuantitativeSr(this.sr);
		},
		changeXh() {
			this.setQuantitativeXh(this.xh);
		},
		onChangeScore() {
			this.setQuantitativeScore(this.score);
		},
	},
	created() {
		this.quantitativeData = this.data.czgl.lhzb;
		this.score = this.data.score.lh;
		this.sjsr = this.quantitativeData.sjsr;
		this.sjxh = this.quantitativeData.sjxh;
		this.setQuantitativeScore(this.score);
	},
};
</script>

<style lang="less" scoped>
	.quantitative-container {
		.score {
			display: inline-block;
			width: 95px;
			height: 95px;
			line-height: 80px;
			text-align: center;
			color: #005ab3;
			font-size: 28px;
			border-radius: 50%;
			border: 10px solid #005ab3;
			margin: 0 30px;
		}

		.quantitative-content {
			.qt-wrap {
				margin-top: 20px;

				&.border {
					border-top: 1px solid #005ab3;
					margin-top: 6px;
				}

				.title {
					display: inline-block;
					background-color: #005ab3;
					font-size: 20px;
					padding: 5px 28px;
					color: #fff;
					font-weight: 700;
					margin: 15px 0;
				}

				.qt-card {
					display: flex;
					padding: 10px 90px;

					.qt-img {
						width: 288px;
						height: 274px;
					}

					.qt-panel {
						display: flex;
						align-items: center;
						padding-left: 90px;

						.qt-panel-item {
							padding-left: 80px;

							&:first-child {
								border-right: 1px solid #ddd;
								padding-right: 80px;
							}

							.qpi-text {
								color: #0d3f78;
								font-size: 18px;
								font-weight: 700;
							}

							.qpi-img {
								width: 65px;
								height: 65px;
								margin: 12px 0 5px;
							}

							.qpi-kcal {
								color: #005ab3;
								border-bottom: 1px solid #005ab3;
								line-height: 35px;
								font-size: 18px;
								text-align: center;
							}
						}
					}
				}
			}

			.qt-footer {
				display: flex;
				align-items: flex-end;
				color: #005ab7;
				font-size: 18px;
				margin: 30px 0;

				.qt-footer-text {
					position: relative;
					padding-left: 45px;
					padding-right: 50px;

					&::after {
						position: absolute;
						left: 0;
						top: -15px;
						content: ' ';
						width: 0;
						height: 0;
						border: 18px solid;
						border-color: transparent transparent #005ab7;
					}
				}

				.qt-card {
					flex: 1;
					display: flex;

					.qf-item {
						display: flex;
						flex: 1;
						align-items: flex-end;
						
						p {
							margin-bottom: 5px;
						}

						.qf-kcal {
							border: 1px solid #005ab3;
							margin: 0 15px;
							font-weight: 700;
						}
					}
				}
			}
		}
	}
</style>
