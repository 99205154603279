<template>
	<div>
		<layout footer="标准来源：《康一生健康管理系统》、《康一生营养运动差值量化管理系统》">
			<adjustment />
		</layout>
		<adjust-dinner />
	</div>
</template>

<script>
import Layout from './layout';
import adjustment from './adjustment';
import adjustDinner from './adjust-dinner';

export default {
	components: {
		Layout,
		adjustment,
		adjustDinner,
	},
};
</script>

<style>
</style>
