<template>
  <div class="daily-content">
    <div class="daily-wrap">
      <h3 class="title react-title" style="margin-bottom: 0">实际运动曲线</h3>
      <div class="daily-card" v-for="(item, index) in factArr" :key="index">
        <div class="daily-card-chart">
          <daily-chart :data="item.data" :date="item.rq" />
        </div>
        <div class="daily-card-panel">
          <div class="dcp-flex">
            <div class="dcp-item">
              <div class="dcp-eny">
                <img src="@/assets/img/manage/6-4.png" alt="img" class="dcp-img">
                <p class="dcp-text">有效消耗量</p>
              </div>
              <span class="dcp-kcal">{{ item.yxxhl }}</span>
            </div>
            <div class="dcp-item">
              <div class="dcp-eny">
                <img src="@/assets/img/manage/6-5.png" alt="img" class="dcp-img">
                <p class="dcp-text">总消耗</p>
              </div>
              <span class="dcp-kcal">{{ item.zxh }}</span>
            </div>
          </div>
          <div class="dcp-flex">
            <div class="dcp-item">
              <div class="dcp-eny">
                <img src="@/assets/img/manage/6-6.png" alt="img" class="dcp-img">
                <p class="dcp-text">有效运动率</p>
              </div>
              <span class="dcp-kcal">{{ item.yxydl }}</span>
            </div>
            <div class="dcp-item">
              <div class="dcp-eny">
                <img src="@/assets/img/manage/6-7.png" alt="img" class="dcp-img">
                <p class="dcp-text">有效运动时长</p>
              </div>
              <span class="dcp-kcal">{{ item.yxydsc }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dailyChart from './daily-chart';
export default {
	props: {
		factArr: {
			type: Array,
			default: () => {
				return [];
			},
		},
	},
	data() {
		return {};
	},
	components: {
		dailyChart,
	},
	methods: {},
};
</script>

<style lang="less" scoped>
  .daily-content {
    margin-top: 15px;
    .daily-text {
      font-size: 18px;
      font-weight: 500;
      color: #005ab3;
    }

    .daily-wrap {
      margin-top: 8px;
      margin-bottom: 20px;
      &.border-top {
        border-top: 1px solid #005ab3;
      }
      .title {
        display: inline-block;
        background-color: #71a93b;
        font-size: 20px;
        padding: 5px 28px;
        color: #fff;
        font-weight: 700;
        margin: 15px 0;
        &.react-title {
          background-color: #005ab3;
        }
      }
      .daily-card {
        display: flex;
        justify-content: space-between;
				align-items: center;
        border-bottom: 1px solid #ddd;
        margin: 20px 0;
        &:last-child {
          border: none;
          margin: 0;
        }

        .daily-card-chart {
          width: 72%;
        }
        .daily-card-panel {
          width: 28%;
          text-align: center;
          .dcp-flex {
            display: flex;
            .dcp-item {
              color: #005ab3;
              flex: 1;
              justify-content: center;
              .dcp-eny {
                width: 85px;
              }
            }
          }
          .dcp-item {
            color: #71a93b;
            display: flex;
            margin-bottom: 10px;
            .dcp-eny {
              text-align: center;
              width: 100px;
              .dcp-img {
                width: 40px;
              }
            }
            .dcp-kcal {
              font-size: 16px;
              font-weight: 700;
              line-height: 40px;
            }
          }
        }
      }
    }
  }
</style>
