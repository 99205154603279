<template>
  <div class="daily-content">
    <navi-title title_ch="日运动评价" title_en="DAILY SPORTS EVALUATION">
      <p class="daily-text">运动消耗量：{{ dailyRate.sjydxhl }}</p>
      <!-- <p class="daily-text" style="color: #df8212;">实际运动消耗量：{{ dailyRate.sjydxhl }}</p> -->
    </navi-title>
    <div class="daily-wrap border-top">
      <h3 class="title">标准日运动曲线</h3>
      <div class="daily-card">
        <div class="daily-card-chart">
          <img src="@/assets/img/manage/sport-img.png" alt="img" width="100%" />
        </div>
        <div class="daily-card-panel" style="padding-left: 45px">
          <div class="dcp-item">
            <div class="dcp-eny">
              <img
                src="@/assets/img/manage/6-1.png"
                alt="img"
                class="dcp-img"
              />
              <p class="dcp-text">目标有效消耗量</p>
            </div>
            <span class="dcp-kcal">{{ dailyRate.bzyxxhl }}kcal</span>
          </div>
          <div class="dcp-item">
            <div class="dcp-eny">
              <img
                src="@/assets/img/manage/6-2.png"
                alt="img"
                class="dcp-img"
              />
              <p class="dcp-text">目标总消耗</p>
            </div>
            <span class="dcp-kcal">{{ dailyRate.mbzxh }}kcal</span>
          </div>
          <div class="dcp-item">
            <div class="dcp-eny">
              <img
                src="@/assets/img/manage/6-3.png"
                alt="img"
                class="dcp-img"
              />
              <p class="dcp-text">有效运动率</p>
            </div>
            <span class="dcp-kcal">{{ dailyRate.yxydl }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import naviTitle from 'components/naviTitle/title';
export default {
	props: {
		dailyRate: {
			type: Object,
			default: () => {
				return {};
			},
		},
	},
	components: {
		naviTitle,
	},
};
</script>

<style lang="less" scoped>
.daily-content {
  margin-top: 30px;
  .daily-text {
    font-size: 18px;
    font-weight: 500;
    color: #005ab3;
  }
  .daily-wrap {
    margin-top: 8px;
    margin-bottom: 20px;
    &.border-top {
      border-top: 1px solid #005ab3;
    }
    .title {
      display: inline-block;
      background-color: #71a93b;
      font-size: 20px;
      padding: 5px 28px;
      color: #fff;
      font-weight: 700;
      margin: 15px 0;
      &.react-title {
        background-color: #005ab3;
      }
    }
    .daily-card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #ddd;
      margin: 20px 0;
      &:last-child {
        border: none;
        margin: 0;
      }

      .daily-card-chart {
        width: 72%;
      }
      .daily-card-panel {
        width: 28%;
        text-align: center;
        .dcp-flex {
          display: flex;
          .dcp-item {
            color: #005ab3;
            flex: 1;
            justify-content: center;
            .dcp-eny {
              width: 85px;
            }
          }
        }
        .dcp-item {
          color: #71a93b;
          display: flex;
          margin-bottom: 10px;
          .dcp-eny {
            text-align: center;
            width: 100px;
            .dcp-img {
              width: 40px;
            }
          }
          .dcp-kcal {
            font-size: 16px;
            font-weight: 700;
            line-height: 40px;
          }
        }
      }
    }
  }
}
</style>
