<template>
	<div>
		<week-rate />
		<daily-rate />
	</div>
</template>

<script>
import weekRate from './week-rate';
import dailyRate from './daily-rate';

export default {
	components: {
		weekRate,
		dailyRate,
	},
};
</script>

<style>
</style>
