<template>
    <div class="title-container">
        <div class="title-wrap">
            <div class="title-card">
                <h1 class="title-ch">{{ title_ch }}</h1>
                <h3 class="title-en">{{ title_en }}</h3>
            </div>
            <div class="title-ft">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
export default {
	props: {
		title_ch: {
			type: String,
			default: '',
		},
		title_en: {
			type: String,
			default: '',
		},
	},
	data() {
		return {};
	},
};
</script>

<style lang="less" scoped>
.title-container {
    .title-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title-card {
            border-left: 6px solid #005AB3;
            padding-left: 18px;
			color: #005AB3;
            .title-ch {
                font-size: 28px;
				font-weight: 700;
            }
            .title-en {
                font-size: 14px;
            }
        }
        .title-ft {}
    }
}
</style>
