<template>
  <div>
    <layout footer="标准来源：《ACSM运动测试与运动处方指南(第十版)》、《人体运动学》、《健康节律运动学》">
      <div class="week-container">
        <div class="week-content">
          <navi-title title_ch="周运动评价" title_en="WEEKLY SPORTS EVALUATION">
            <table class="table">
              <tr>
                <td class="table-th">7天有效消耗</td>
                <td>{{ weekRate.qtyxxh }}kcal</td>
                <td class="table-th">7天有效消耗时间</td>
                <td>{{ weekRate.qtyxxhsj }}</td>
              </tr>
              <tr>
                <td class="table-th">任务差额</td>
                <td>{{ weekRate.xh_rwce }}kcal</td>
                <td class="table-th">任务差额</td>
                <td>{{ weekRate.sj_rwce }}</td>
              </tr>
            </table>
          </navi-title>
          <week-rate-cmp />
        </div>
			</div>
		</layout>
		<layout footer="标准来源：《ACSM运动测试与运动处方指南(第十版)》、《人体运动学》、《健康节律运动学》">
			<daily-standard-cmp :daily-rate="dailyRate"></daily-standard-cmp>
			<daily-rate-cmp :fact-arr="factArr" />
		</layout>
	</div>
  
</template>

<script>
import Layout from './layout';
import naviTitle from 'components/naviTitle/title';
import weekRateCmp from './week-rate-cmp';
import dailyStandardCmp from './daily-standard-cmp';
import dailyRateCmp from './daily-rate-cmp';
import { mapGetters } from 'vuex';
export default {
	data() {
		return {
			weekRate: {},
			dailyRate: {},
		};
	},
	components: {
		Layout,
		naviTitle,
		weekRateCmp,
		dailyStandardCmp,
		dailyRateCmp,
	},
	computed: {
		...mapGetters({ data: 'yyAllData' }),
		factArr() {
			return this.data.rydpj.sjydqx.slice(0, 2);
		},
	},
	created() {
		this.weekRate = this.data.zydpj;
		this.dailyRate = this.data.rydpj;
	},
};
</script>

<style lang="less" scoped>
  .week-container {
    background-color: #fff;
    padding-top: 20px;
    .week-content {
      .table {
        tr {
          border: 1px solid #ddd;
        }
        td {
          width: 160px;
          line-height: 28px;
          text-align: center;
        }
        .table-th {
          background-color: #005ab3;
          color: #fff;
        }
      }
    }
  }
</style>
