<template>
  <div class="questions-container">
    <ul class="questions-wrap">
      <li class="questions-item">
        <div class="questions-title">
          <h3 class="title">现有问题汇总</h3>
          <img src="@/assets/img/manage/info-icon.png" alt="img" class="qt-img">
        </div>
        <div class="questions-card">
          <p class="questions-card-title">一、能量摄入</p>
          <div class="questions-panel">
            <div class="questions-panel-item">
              <img src="@/assets/img/manage/9-1.png" alt="img" class="qp-img" v-if="questions.name_1_type !== 'wlys'">
							<img src="@/assets/img/manage/8-5.png" alt="img" class="qp-img" v-else>
              <p class="qp-text">{{ questions.name_1 }}</p>
            </div>
            <p class="questions-panel-text" v-html="replaceBreak(questions.ts_1)"></p>
            <!-- <div class="questions-panel-img">
              <img src="@/assets/img/target-tzfl.png" alt="img" class="qp-img">
            </div> -->
          </div>
        </div>
      </li>
      <li class="questions-item">
        <div class="questions-card">
          <p class="questions-card-title">二、能量摄入</p>
          <div class="questions-panel">
            <div class="questions-panel-item">
							<img src="@/assets/img/manage/9-1.png" alt="img" class="qp-img" v-if="questions.name_2_type !== 'wlys'">
							<img src="@/assets/img/manage/8-5.png" alt="img" class="qp-img" v-else>
              <p class="qp-text">{{ questions.name_2 }}</p>
            </div>
            <p class="questions-panel-text" v-html="replaceBreak(questions.ts_2)"></p>
            <!-- <div class="questions-panel-img">
              <img src="@/assets/img/target-tzfl.png" alt="img" class="qp-img">
            </div> -->
          </div>
        </div>
      </li>
      <li class="questions-item">
        <div class="questions-card">
          <p class="questions-card-title">三、运动消耗</p>
          <div class="questions-panel">
            <div class="questions-panel-item">
              <img src="@/assets/img/manage/8-6.png" alt="img" class="qp-img">
              <p class="qp-text">{{ sportsDesc.name_3 }}</p>
            </div>
            <p class="questions-panel-text" v-html="replaceBreak(sportsDesc.ts_3)"></p>
            <!-- <div class="questions-panel-img">
              <img src="@/assets/img/target-tzfl.png" alt="img" class="qp-img">
            </div> -->
          </div>
        </div>
      </li>
      <li class="questions-item">
        <div class="questions-card">
          <p class="questions-card-title">四、运动消耗</p>
          <div class="questions-panel">
            <div class="questions-panel-item">
              <img src="@/assets/img/manage/8-7.png" alt="img" class="qp-img">
              <p class="qp-text">{{ sportsDesc.name_4 }}</p>
            </div>
            <p class="questions-panel-text" v-html="replaceBreak(sportsDesc.ts_4)"></p>
            <!-- <div class="questions-panel-img">
              <img src="@/assets/img/target-tzfl.png" alt="img" class="qp-img">
            </div> -->
          </div>
        </div>
      </li>
      <li class="questions-item">
        <div class="questions-card">
          <p class="questions-card-title">五、量化管理</p>
          <div class="questions-panel">
            <div class="questions-panel-item">
              <img src="@/assets/img/manage/8-8.png" alt="img" class="qp-img">
              <p class="qp-text">{{ questions.name_5 }}</p>
            </div>
            <p class="questions-panel-text" v-html="replaceBreak(questions.ts_5)"></p>
            <!-- <div class="questions-panel-img">
              <img src="@/assets/img/target-tzfl.png" alt="img" class="qp-img">
            </div> -->
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	data() {
		return {
			questions: {},
		};
	},
	computed: {
		...mapGetters(['sportsDesc', 'yyAllData']),
	},
	methods: {
		replaceBreak(val) {
			if (val) return val.replace(/\r/g, '<br>');
			else return '';
		},
	},
	created() {
		this.questions = this.yyAllData.czgl.xywthz;
	},
};
</script>

<style lang="less" scoped>
  .questions-container {
    .questions-wrap {
      border: 1px solid #005ab3;

      .questions-item {
        border-bottom: 1px solid #005ab3;
        padding: 10px 15px;
        &:last-child {
          border-bottom: none;
        }

        .questions-title {
          .title {
            display: inline-block;
            background-color: #005ab3;
            font-size: 20px;
            padding: 5px 28px;
            color: #fff;
            font-weight: 700;
            margin: 15px 0;
          }

          .qt-img {
            width: 30px;
            margin-left: 5px;
          }
        }

        .questions-card {
          color: #005ab3;

          .questions-card-title {
            font-size: 20px;
            padding-bottom: 15px;
          }

          .questions-panel {
            display: flex;
            align-items: center;

            .questions-panel-item {
              display: flex;
              flex-direction: column;
              align-items: center;
              padding-left: 18px;
              width: 15%;

              .qp-img {
                width: 60px;
              }

              .qp-text {}
            }

            .questions-panel-text {
              flex: 1;
              color: #005ab3;
              font-size: 18px;
              font-weight: bold;
              margin: 0 10px 0 30px;
              line-height: 30px;
				font-size: 16px;
				font-weight: 700;
            }

            .questions-panel-img {
              border-left: 1px solid #ddd;
              padding: 0 20px;

              .qp-img {
                width: 60px;
                height: 60px;
              }
            }
          }
        }
      }
    }
  }
</style>
