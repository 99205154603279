<template>
	<layout nameCh="评价与反馈" nameEn="EVALUATION AND FEEDBACK" footer="标准来源：《康一生健康管理系统》、《康一生营养运动差值量化管理系统》">
		<div class="rate-container" ref="rate">
			<div class="rate-card">
				<h2 class="rate-card-title">导师评价</h2>
				<div class="rate-card-textarea">
					<div class="like-textarea" contenteditable="true" ref="rateGuider"></div>
					<div class="rate-card-sign">
						<span>签字: </span><Input type="text" v-model="signGuider"></Input>
					</div>
				</div>
			</div>
			<div class="rate-card">
				<h2 class="rate-card-title">自我感受</h2>
				<div class="rate-card-textarea">
					<div class="like-textarea" contenteditable="true" ref="rateSelf"></div>
					<div class="rate-card-sign">
						签字: <Input type="text" v-model="signSelf"></Input>
					</div>
				</div>
			</div>
			<div class="rate-card">
				<h2 class="rate-card-title">亲友评价</h2>
				<div class="rate-card-textarea">
					<div class="like-textarea" contenteditable="true" ref="rateFriends"></div>
					<div class="rate-card-sign">
						签字: <Input type="text" v-model="signFriends"></Input>
					</div>
				</div>
			</div>
			<div class="rate-date">
				<p>下次交作业日期</p>
				<img src="@/assets/img/manage/date-icon.png" alt="img">
				<Date-picker v-model="date" type="date" placeholder="选择日期" confirm style="width: 200px" @on-ok="onScrollTop"></Date-picker>
			</div>

			<div class="report-wrap" v-if="selectVisible">
				<Button type="info" @click="finishReportHandle">完成报告</Button>
			</div>
		</div>
		<Modal v-model="warnModal" title="提示" width="70%" class="week-rate-iframe">
			<p class="modal-text">界面中<span>{{warnText}}</span>内容(未填写/未选择)</p>
			<p class="modal-text">请填写完成再次点击<span>生成PDF</span></p>
		</Modal>
	</layout>
</template>

<script>
import dayjs from 'dayjs';
import reportSportsService from '@/services/reportSportsService';
import Layout from './layout';
import { mapGetters, mapMutations } from 'vuex';
export default {
	data() {
		return {
			textarea: '',
			nextWorkShow: false,
			warnText: '',
			warnModal: false,
			signGuider: '',
			signSelf: '',
			signFriends: '',
			date: '',
		};
	},
	components: {
		Layout,
	},
	computed: {
		...mapGetters([
			'quantitative_sr',
			'quantitative_xh',
			'yyAllData',
			'reportUserData',
			'selectVisible',
			'cacheProject',
			'habits',
			'sportsDesc',
			'quantitative_score',
			'adjustment_score',
			'adjustment_site',
			'adjustment_suggestion',
			'adjustment_diease',
			'noruishment_suggestion',
			'noruishment_diease',
			'weekFillData',
			'adjustment_count',
			'sport_score',
		]),
		adjustSr() {
			return this.yyAllData.czgl.lhzb.sjsr + this.quantitative_sr;
		},
		adjustXh() {
			return this.yyAllData.czgl.lhzb.sjxh + this.quantitative_xh;
		},
	},
	methods: {
		...mapMutations(['setNextWorkDate']),
		validate() {
			if (this.cacheProject.includes('摄入量评估')) {
				// 生成饮食报告
				console.log(this.habits);
				if (this.habits.length !== 2) {
					this.warnText = '饮食习惯';
					this.warnModal = true;
					return false;
				}
			}

			if (this.cacheProject.includes('能量消耗管理')) {
				if (Object.keys(this.sportsDesc).length === 0) {
					this.warnText = '周运动评价';
					this.warnModal = true;
					return false;
				}
			}

			if (this.cacheProject.includes('差值量化管理')) {
				if (this.quantitative_score === 0) {
					this.warnText = '量化指标评分';
					this.warnModal = true;
					return false;
				}
				// if (this.quantitative_sr === 0) {
				// 	this.warnText = '量化指标调整摄入值'
				// 	this.warnModal = true
				// 	return false
				// }
				// if (this.quantitative_xh === 0) {
				// 	this.warnText = '量化指标调整消耗值'
				// 	this.warnModal = true
				// 	return false
				// }
			}

			if (this.cacheProject.includes('量化管理处方')) {
				if (this.adjustment_score === 0) {
					this.warnText = '调整方案评分';
					this.warnModal = true;
					return false;
				}

				if (!this.adjustment_site) {
					this.warnText = '运动场地';
					this.warnModal = true;
					return false;
				}

				if (!this.adjustment_suggestion) {
					this.warnText = '运动作业';
					this.warnModal = true;
					return false;
				}

				if (this.reportUserData.disease.length && !this.adjustment_diease) {
					this.warnText = '运动作业';
					this.warnModal = true;
					return false;
				}

				if (!this.noruishment_suggestion) {
					this.warnText = '营养作业';
					this.warnModal = true;
					return false;
				}

				if (this.reportUserData.disease.length && !this.noruishment_diease) {
					this.warnText = '营养作业';
					this.warnModal = true;
					return false;
				}
			}

			// if (this.cacheProject.includes('评价与反馈')) {
			// 	if (!this.$refs.rateGuider.value) {
			// 		this.warnText = '导师评价'
			// 		this.warnModal = true
			// 		return false
			// 	}

			// 	if (!this.signGuider) {
			// 		this.warnText = '导师评价签名'
			// 		this.warnModal = true
			// 		return false
			// 	}

			// 	if (!this.$refs.rateSelf.value) {
			// 		this.warnText = '自我感受'
			// 		this.warnModal = true
			// 		return false
			// 	}

			// 	if (!this.signSelf) {
			// 		this.warnText = '自我感受签名'
			// 		this.warnModal = true
			// 		return false
			// 	}

			// 	if (!this.$refs.rateFriends.value) {
			// 		this.warnText = '亲友评价'
			// 		this.warnModal = true
			// 		return false
			// 	}

			// 	if (!this.signFriends) {
			// 		this.warnText = '亲友评价签名'
			// 		this.warnModal = true
			// 		return false
			// 	}
			// }

			if (!this.date) {
				this.$Message.warning('请选择下次交作业日期');
				return false;
			}

			return true;
		},
		finishReportHandle() {
			if (this.validate()) {
				reportSportsService
					.finishReport({
						member_id: localStorage.getItem('member_id'),
						yssj: this.habits[0], // 饮食习惯
						prfs: this.habits[1], //烹饪方式
						sj: this.weekFillData, // 周运动评价数据
						quantitative_score: this.quantitative_score, // 量化指标评分
						sport_score: this.sport_score, // 调整方案运动评分
						frequency: this.adjustment_count, // 运动频次
						site: this.adjustment_site, // 运动场地
						sport_suggestion: this.adjustment_suggestion, // 运动作业建议
						sport_diease: this.adjustment_diease, // 运动作业疾病
						adjustment_score: this.adjustment_score, // 调整饮食评价分数
						dinner_suggestion: this.noruishment_suggestion, // 饮食营养作业
						dinner_diease: this.noruishment_diease, // 饮食疾病作业
						guider_rate: this.$refs.rateGuider.value, // 导师评价
						guider_sign: this.signGuider, // 导师签名
						self_rate: this.$refs.rateSelf.value, // 自我感受评价
						self_sign: this.signSelf, // 自我感受签名
						friends_rate: this.$refs.rateFriends.value, // 亲友评价
						friends_sign: this.signFriends, // 亲友签名
						next_date: dayjs(this.date).format('YYYY-MM-DD'), // 下次作业日期
						tzh_sr: this.adjustSr,
						tzh_xh: this.adjustXh,
						bzsrzl: this.yyAllData.srlpg.bzsrzl,
						sjsrzl: this.yyAllData.srlpg.sjsrzl,
					})
					.then(() => {
						this.nextWorkShow = true;
					});
			}
		},
		onScrollTop() {
			this.setNextWorkDate(this.date);
			// window.scrollTo({
			// 	top: 0,
			// 	behavior: 'smooth'
			// })
		},
	},
};
</script>

<style lang="less" scoped>
	.rate-container {
		.rate-card {
			margin-bottom: 20px;

			.rate-card-title {
				color: #005AB3;
				font-size: 18px;
				margin-bottom: 8px;
			}

			.rate-card-textarea {
				height: 176px;
				border: 1px solid #005AB3;

				.like-textarea {
					height: 130px;
					outline: none;
					font-size: 18px;
					font-weight: bold;
					overflow: auto;
				}

				.rate-card-sign {
					text-align: right;
					color: #005AB3;
					font-size: 16px;
					padding-right: 120px;
				}
			}
		}

		.rate-date {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			width: 100%;
			color: #005AB3;
			margin-bottom: 10px;
			font-size: 18px;
			font-weight: bold;

			>img {
				width: 40px;
			}
		}

		.report-wrap {
			text-align: right;
		}
	}

	.modal-text {
		line-height: 35px;

		>span {
			color: #ff9900;
		}
	}

	:deep(textarea.ivu-input) {
		border: none;
	}

	:deep(.ivu-input):hover {
		border: none;
	}
</style>
