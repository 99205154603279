<template>
  <div v-if="data.length > 0">
    <layout v-for="i in 4" :key="i">
      <div class="content-header">
        <div class="content-header__text">
          <p>饮食分析</p>
          <p>DIET ANALYSIS</p>
        </div>
        <div class="content-header__img">
          <img src="@/assets/img/quantify-drink.png">
        </div>
      </div>
      <div class="main-title">微量元素摄入情况</div>
      <template v-for="(item, index) in data.slice((i-1) * 3, (i-1) * 3 + 3)">
        <div class="content-item" :key="item.name + index">
          <div class="content-img">
            <img :src="getImage(mapImage(item.name))" width="150" />
          </div>
          <div class="content-value">
            <p>
              <span>标准值</span>
              <span class="content-value-panel">{{ item.bzj }}</span>
              {{ mapUnit(item.name) }}
              <span>
                <img
                  :src="getImage(`${mapImage(item.name)}-icon`)"
                  class="content-value-img"
                  v-for="i in 3"
                  :key="i"
                />
              </span>
            </p>
            <p style="margin-top: 15px;">
              <span>实际值</span>
              <span class="content-value-panel">{{ item.sjz }}</span>
              {{ mapUnit(item.name) }}
              <span>
                <img
                  :src="getImage(`${mapImage(item.name)}-icon`)"
                  class="content-value-img"
                  v-for="i in Math.floor(item.tb)" :key="i"
                />
              </span>
            </p>
          </div>
          <div class="content-tip">
            <p>
              <img src="@/assets/img/quantify-tip.png" width="26">
              <span class="text-18">提示</span>
            </p>
            <p class="content-tip-text mb10" v-html="replaceBreak(item.bzts)"></p>
            <p
              class="content-tip-text"
              :class="{'text-red': item.level !== 0}"
              v-html="replaceBreak(item.ts)"
              v-if="item.level !== 0">
            </p>
          </div>
        </div>
        <div class="divider divider__normal" v-if="index !== 2" :key="item.name"></div>
      </template>
    </layout>
  </div>
</template>

<script>
import Layout from './layout';

export default {
	components: {
		Layout,
	},
	props: {
		data: {
			type: Array,
		},
	},
	methods: {
		replaceBreak(val) {
			if (val) return val.toString().replace(/\r/g, '<br>');
			else return '';
		},
		mapUnit(val) {
			switch (val) {
				case 'VA':
					return 'μg';
				case '膳食纤维':
					return 'g';
				default:
					return 'mg';
			}
		},
		mapImage(val) {
			let path = '';
			switch (val) {
				case 'VA':
					path = 'va';
					break;
				case 'VB1':
					path = 'vb1';
					break;
				case 'VB2':
					path = 'vb2';
					break;
				case 'VC':
					path = 'vc';
					break;
				case 'VE':
					path = 've';
					break;
				case 'VPP':
					path = 'vpp';
					break;
				case '钙':
					path = 'ca';
					break;
				case '铁':
					path = 'fe';
					break;
				case '钠':
					path = 'na';
					break;
				case '胆固醇':
					path = 'chol';
					break;
				case '膳食纤维':
					path = 'df';
					break;
				default:
					path = 'va';
					break;
			}
			return path;
		},
		getImage(img) {
			return new URL(`/src/assets/img/quantify-${img}.png`, import.meta.url).href;
		},
	},
};
</script>

<style lang="less" scoped>
.content-header {
  display: flex;
  color: #005AB3;
  padding-bottom: 20px;
  margin: 30px 0;
  border-bottom: 1px solid #005AB3;
  p:first-child {
    font-size: 28px;
    font-weight: bold;
  }
  &__text {
    margin-right: 20px;
    border-left: 6px solid #005AB3;
    padding-left: 18px;
  }
  &__img {
    width: 65px;
    img {
      width: 100%;
    }
  }
}
.main-title {
  font-size: 24px;
  font-weight: bold;
  color: #005AB3;
  &::before {
    content: '';
    display: inline-block;
    border: 12px solid transparent;
    border-top: 12px solid #005AB3;
    border-left: 12px solid #005AB3;
    margin-right: 4px;
  }
  &::after {
    content: '';
    display: inline-block;
    border: 12px solid transparent;
    border-bottom: 12px solid #005AB3;
    border-right: 12px solid #005AB3;
    margin-left: 4px;
    margin-bottom: -4px;
  }
}
.text-18 {
  font-size: 18px;
  font-weight: bold;
  padding-left: 10px;
  vertical-align: middle;
}
.content-item {
  display: flex;
  margin: 60px 0;
  align-items: center;
  .content-img {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    color: #005AB3;
  }
  .content-value {
    margin: 0 40px;
    color: #005AB3;
    font-size: 18px;
    flex: 1;
    .content-value-img {
      height: 50px;
      margin-left: 20px;
    }
    .content-value-panel {
      padding: 2px 0;
      border: solid 1px #005AB3;
      border-radius: 4px;
      margin: 0 5px;
      width: 80px;
      display: inline-block;
      text-align: center;
    }
  }
  .content-tip {
    color: #005AB3;
    width: 300px;
    font-size: 18px;
    font-weight: bold;
    .content-tip-text {
      margin-top: 10px;
    }
  }
}
.divider {
  background-color: #005AB3;
  width: 100%;
  &__normal {
    height: 1px;
  }
}
.text-red {
  color: red;
}
</style>
