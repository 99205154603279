<template>
	<div>
		<layout footer="标准来源：《康一生健康管理系统》、《康一生营养运动差值量化管理系统》">
			<questions></questions>
		</layout>
		<layout footer="标准来源：《康一生健康管理系统》、《康一生营养运动差值量化管理系统》">
			<quantitative></quantitative>
		</layout>
	</div>
</template>

<script>
import Layout from './layout';
import questions from './questions';
import quantitative from './quantitative';

export default {
	components: {
		Layout,
		questions,
		quantitative,
	},
};
</script>

<style>
</style>
