<template>
  <div class="daily" ref="dailyChart"></div>
</template>

<script>
import echarts from 'echarts';
export default {
	props: {
		data: {
			type: Array,
			default: () => {
				return [];
			},
		},
		date: {
			type: String,
			default: '',
		},
	},
	methods: {
		initChart() {
			// 初始化实例
			this.Chart = echarts.init(this.$refs.dailyChart);
			let xData = [];
			let yData = [];
			this.data.forEach((item) => {
				xData.push(item.time);
				yData.push(item.value);
			});
			let options = {
				title: {
					text: `日期：${this.date}`,
					textStyle: {
						color: '#005ab3',
						fontSize: '12',
					},
					padding: 25,
				},
				backgroundColor: '#fff',
				xAxis: [
					{
						type: 'category',
						boundaryGap: true,
						axisLine: {
							onZero: false,
							lineStyle: {
								color: '#0e5ba6',
							},
						},
						axisLabel: {
							color: '#0e5ba6',
						},
						splitLine: {
							show: false,
						},
						data: xData,
					},
				],
				yAxis: [
					{
						type: 'value',
						splitLine: {
							show: false,
						},
						axisLabel: {
							color: '#0e5ba6',
						},
						axisLine: {
							lineStyle: {
								color: '#0e5ba6',
							},
						},
					},
				],
				tooltip: {
					show: false,
				},
				series: [
					{
						name: '',
						type: 'line',
						color: '#046dd5',
						symbol: 'circle',
						symbolSize: 8,
						showSymbol: true,
						data: yData,
					},
				],
			};
			this.Chart.setOption(options);
		},
	},
	mounted() {
		this.initChart();
	},
	watch: {
		data() {
			this.initChart();
		},
	},
};
</script>

<style>
  .daily {
    width: 100%;
    height: 206px;
  }
</style>
