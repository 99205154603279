<template>
	<div class="adjust-container">
		<layout footer="标准来源：《康一生健康管理系统》、《康一生营养运动差值量化管理系统》">
			<navi-title title_ch="调整方案" title_en="ADJUSTMENT PLAN">
				<div class="daily-score">
					<Input-number :min="1" v-model="score" style="width: 63px;"  @on-change="onChangeScore"></Input-number>
					<span>分</span>
				</div>
			</navi-title>
			<div class="adjust-content">
				<div class="adjust-wrap border">
					<div class="plan-wrap">
						<img src="@/assets/img/manage/9-1.png" alt="img" class="adj-img">
						<p class="plan">饮食调整方案：<span>阶段饮食摄入目标 {{ totalIntake }}kcal</span></p>
					</div>
					<ul class="plan-list">
						<li v-for="(value, key) in nutritionData" :key="key">
							<div class="plan-item">
								<img :src="getImage(mapImage(key))" alt="img" class="plan-item-img">
								<p class="plan-item-text">{{ key }}</p>
							</div>
							<div class="plan-card">
								<div class="plan-card-item" v-for="(row, index) in value" :key="key + index">
									<div class="pci-food">
										<img :src="getImage(mapImage(row[0]))" alt="img">
										<p class="pci-food-text">{{ row[0] }}</p>
									</div>
									<div class="pci-text">
										<p class="pci-weight">重量{{ row[1] }}</p>
										<!-- <p class="pci-ency">能量{{ row[2] }}</p> -->
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</layout>
		<layout footer="标准来源：《康一生健康管理系统》、《康一生营养运动差值量化管理系统》">
			<nourishment />
			<div class="rate-date">
				<p>下次交作业日期</p>
				<img src="@/assets/img/manage/date-icon.png" alt="img">
				<Date-picker v-model="nextWorkDate" type="date" placeholder="选择日期" style="width: 200px"></Date-picker>
			</div>
		</layout>
	</div>
</template>

<script>
import Layout from './layout';
import naviTitle from 'components/naviTitle/title';
import nourishment from './nourishment';
import { mapGetters, mapMutations } from 'vuex';
import nutrition from './nutrition-new';
export default {
	data() {
		return {
			index: 2000,
			nutritionData: [],
			score: 0,
		};
	},
	computed: {
		...mapGetters(['quantitative_sr', 'yyAllData', 'nextWorkDate']),
		totalIntake() {
			// 总摄入
			return this.yyAllData.czgl.lhzb.sjsr + this.quantitative_sr;
		},
	},
	components: {
		Layout,
		naviTitle,
		nourishment,
	},
	methods: {
		...mapMutations(['setAdjustmentScore']),
		onChangeScore() {
			this.setAdjustmentScore(this.score);
		},
		mapImage(val) {
			let path = '';
			switch (val) {
				case '早餐':
					path = 'pm';
					break;
				case '午餐':
					path = 'am';
					break;
				case '晚餐':
					path = 'night';
					break;
				case '加餐':
					path = 'more';
					break;
				case '其他':
					path = 'diet';
					break;
				case '谷薯类（克）':
					path = 'rice';
					break;
				case '粥（克）':
					path = 'rice';
					break;
				case '蔬菜（克）':
					path = 'vega';
					break;
				case '水果（克）':
					path = 'fruit';
					break;
				case '鸡蛋（个）':
					path = 'egg';
					break;
				case '蛋类':
					path = 'egg';
					break;
				case '大豆及坚果':
					path = 'bean';
					break;
				case '水产品（克）':
					path = 'fish';
					break;
				case '鱼虾蟹（克）':
					path = 'fish';
					break;
				case '水（毫升）':
					path = 'water';
					break;
				case '牛奶（毫升）':
					path = 'milk';
					break;
				case '液体奶（ml）':
					path = 'milk';
					break;
				case '食盐（克）':
					path = 'solt';
					break;
				case '用油（克）':
					path = 'oil';
					break;
				case '米饭（熟）（克）':
					path = 'mf';
					break;
				case '杂粮（生）（克）':
					path = 'zl';
					break;
				case '五花肉（克）':
					path = 'rou';
					break;
				case '北豆腐（老）（克）':
					path = 'dfk';
					break;
				default:
					path = 'bean';
					break;
			}
			return path;
		},
		getImage(img) {
			return new URL(`/src/assets/img/quantify-${img}.png`, import.meta.url).href;
		},
	},
	mounted() {
		const number = Math.round(this.totalIntake / 100) * 100;
		if (number >= 1000 && number <= 2400) {
			this.index = number;
		}
		this.nutritionData = nutrition[`${this.index}`];

		this.score = this.yyAllData.score.ys;
		this.setAdjustmentScore(this.score);
	},
};
</script>

<style lang="less" scoped>
	.adjust-container {
		background-color: #fff;

		.score {
			display: inline-block;
			width: 95px;
			height: 95px;
			line-height: 80px;
			text-align: center;
			color: #005ab3;
			font-size: 28px;
			border-radius: 50%;
			border: 10px solid #005ab3;
			margin: 0 30px;
		}

		.adjust-content {
			.adjust-wrap {
				margin-top: 20px;

				&.border {
					border-top: 1px solid #005ab3;
				}

				.title {
					display: inline-block;
					background-color: #005ab3;
					font-size: 20px;
					padding: 5px 28px;
					color: #fff;
					font-weight: 700;
					margin: 15px 0;
				}

				.plan-wrap {
					display: flex;
					align-items: center;

					.adj-img {
						width: 50px;
						margin-right: 10px;
					}

					.plan {
						color: #005ab3;
						font-weight: 700;
						font-size: 22px;
						padding: 20px 0;

						>span {
							padding-bottom: 6px;
							font-weight: 400;
							border-bottom: 1px solid #005ab3;
						}
					}
				}

				.plan-list {
					>li {
						display: flex;
						align-items: center;
						border-bottom: 1px solid #005ab3;
						padding: 10px 60px;

						.plan-item {
							margin-right: 80px;
							text-align: center;

							.plan-item-img {
								width: 55px;
								margin-bottom: 5px;
							}

							.plan-item-text {
								font-size: 20px;
								font-weight: 700;
								color: #005ab3;
							}
						}

						.plan-card {
							color: #71a93b;
							display: flex;
							flex: 1;

							.plan-card-item {
								display: flex;
								flex: 1;

								.pci-food {
									margin-right: 15px;
									text-align: center;

									>img {
										width: 55px;
										margin-bottom: 5px;
									}

									.pci-food-text {
										font-size: 18px;
										font-weight: 700;
									}
								}

								.pci-text {
									font-size: 16px;

									>p {
										margin-top: 5px;
									}
								}
							}
						}
					}
				}

				.adjust-textarea {
					display: block;
					width: 100%;
					border: 1px solid #005ab3;
					margin-bottom: 10px;
					padding: 8px;
					.textarea {
						color: #005AB3;
						font-size: 16px;
						font-weight: 700;
						margin-bottom: 15px;
					}
					.textarea-content {
						padding: 10px 0;
						display: block;
						width: 100%;
						border: 1px solid #005ab3;
						margin-bottom: 10px;
						padding: 8px;
						font-size: 18px;
						font-weight: bold;
					}
				}
			}
		}

		.rate-date {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			width: 100%;
			color: #005AB3;
			margin-bottom: 10px;
			font-size: 18px;
			font-weight: bold;
			>img {
				width: 40px;
			}
		}
	}
</style>
